import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from '../../components'
import { PaymentProgress } from 'pages/payment/PaymentProgress'
import { PaymentSummaryType } from 'pages/payment/types'
import { useTranslation } from 'react-i18next'
import { useGetStorage } from 'hooks'
import { constants, findPermission } from 'helpers'

export const VillageClientPayment: React.FC<{
    paymentSummary: PaymentSummaryType
    isPartiallyDelivered?: boolean
}> = ({ paymentSummary, isPartiallyDelivered }) => {
    const { t } = useTranslation()

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)

    const canViewPaymentHistory = findPermission(
        userPermissions,
        constants.VIEW_PAYMENTS
    )

    const WithPaymentHistoryLink = ({
        children
    }: {
        children: React.ReactElement
    }) => {
        if (canViewPaymentHistory) {
            return (
                <Link
                    reloadDocument={false}
                    to="payment-history"
                    className="card-link"
                    aria-label="payment-history-link"
                >
                    {children}
                </Link>
            )
        }

        return children as React.ReactElement
    }

    return (
        <Card shadow className="village-client-payment-card">
            <h2 className="client-payment--header">{t('village.payments')}</h2>
            <WithPaymentHistoryLink>
                <PaymentProgress
                    paymentSummary={paymentSummary}
                    isPartiallyDelivered={isPartiallyDelivered}
                />
            </WithPaymentHistoryLink>
        </Card>
    )
}
